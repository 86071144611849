import {Component, OnInit} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html'
})
export class ApplicationComponent implements OnInit {
  token: string;

  constructor(private db: AngularFirestore, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
    this.db.collection('parametres').doc('application').get().subscribe((document) => {
      if (this.token === document.get('token')) {
        window.location.href = document.get('lien');
      } else {
        this.message('Impossible de télécharger l\'application');
      }
    });
  }


  message(message, duration = 3000) {
    this.snackBar.open(message, null, {duration});
  }
}
