<div style="padding: 50px;">
  <mat-card color="light" style="border-radius: 0%;">
    <mat-card-header style="margin-bottom: 5px;">
      <table>
        <tr>
          <td>
            <mat-card-title>Nouveau projet</mat-card-title>
          </td>
          <td style="text-align: right;">
            <button mat-raised-button type="button" color="primary"
                    (click)="valider()">Valider
            </button>
          </td>
        </tr>
      </table>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <br>
      <form class="example-form">
        <table style="width: 100%;">
          <tr style="width: 100%;">
            <td>
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Libellé</mat-label>
                <input matInput [(ngModel)]="nouveauProjet.libelle" name="nouveauProjet.libelle">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Zone</mat-label>
                <input type="text" matInput placeholder="Zone" [matAutocomplete]="auto" [(ngModel)]="nouveauProjet.zone"
                       (keyup)="filterZone()" name="nouveauProjet.zone">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let zone of zonesSearch" [value]="zone">{{zone}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Utilisateur</mat-label>
                <mat-select [(ngModel)]="nouveauProjet.utilisateur" name="nouveauProjet.utilisateur">
                  <mat-option *ngFor="let utilisateur of utilisateurs" [value]="utilisateur.id">
                    {{utilisateur.prenomNom}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <mat-label>Fichier KML</mat-label>
                <ngx-mat-file-input [(ngModel)]="fichierKml" name="fichierKml"></ngx-mat-file-input>
                <mat-icon matSuffix>get_app</mat-icon>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </form>
    </mat-card-content>
  </mat-card>

  <br><br>

  <mat-form-field>
    <mat-label>Recherche</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Recherche">
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="identifiant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Identifiant</th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>

      <ng-container matColumnDef="libelle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Projet</th>
        <td mat-cell *matCellDef="let row"> {{row.libelle}} </td>
      </ng-container>

      <ng-container matColumnDef="nbPoteaux">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de reprises</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="row.nbPoteaux">
            <button mat-icon-button color="primary" (click)="nombreReprises(row.nbPoteaux, row.kml)" style="color: #00bbf0;">
                  <mat-icon>info</mat-icon>
                </button>
            </span>
          <span *ngIf="!row.nbPoteaux">Aucune reprise</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="zone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zone</th>
        <td mat-cell *matCellDef="let row"> {{row.zone}} </td>
      </ng-container>

      <ng-container matColumnDef="utilisateur">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Utilisateur</th>
        <td mat-cell *matCellDef="let row"> {{row.utilisateur}} </td>
      </ng-container>

      <ng-container matColumnDef="dateCloture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de cloture</th>
        <td mat-cell *matCellDef="let row"><span
          *ngIf="row.dateCloture">{{row.dateCloture.seconds * 1000 | date: 'dd/MM/yyyy hh:mm'}}</span></td>
      </ng-container>

      <ng-container matColumnDef="actif">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Etat</th>
        <td mat-cell *matCellDef="let row">
          <span *ngIf="!row.actif" style="color: #d32f2f;">FERMÉ</span>
          <span *ngIf="row.actif" style="color: #689f38;">OUVERT</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions</th>
        <td mat-cell *matCellDef="let row">
          <table>
            <tr>
              <td>
                <button mat-icon-button color="primary" (click)="genererKmlManquants(row)" matTooltip="Générer un kml avec les poteaux manquants">
                  <mat-icon color="warn">fmd_bad</mat-icon>
                </button>
              </td>
              <td>
                <button mat-icon-button color="primary" (click)="comparaisonImage(row)" matTooltip="Comparaison des images" style="color: #00bbf0;">
                  <mat-icon>insert_photo</mat-icon>
                </button>
              </td>
              <td>
                <span *ngIf="row.actif">
                  <button mat-icon-button style="color: #d32f2f;" matTooltip="Fermer" (click)="changerEtat(row, false)">
                    <mat-icon>lock</mat-icon>
                  </button></span>
                <span *ngIf="!row.actif">
                  <button mat-icon-button style="color: #689f38;" matTooltip="Ouvrir" (click)="changerEtat(row, true)">
                    <mat-icon>lock_open</mat-icon>
                  </button></span>
              </td>
              <td>
                <button mat-icon-button color="primary" (click)="changerTechnicien(row)" style="color: #f57c00;" matTooltip="Changer de technicien">
                  <mat-icon>person</mat-icon>
                </button>
              </td>
              <td>
                <button [disabled]="row.actif" mat-icon-button color="primary" matTooltip="Générer les kmls"
                        (click)="genererKml(row.id, row.libelle)">
                  <mat-icon>get_app</mat-icon>
                </button>
              </td>
              <td>
                <button [disabled]="row.actif" mat-icon-button color="accent" (click)="archiver(row)" matTooltip="Archiver">
                  <mat-icon>inbox</mat-icon>
                </button>
              </td>
              <td>
                <button [disabled]="row.actif" mat-icon-button color="warn" (click)="supprimer(row.id)" matTooltip="Supprimer">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </table>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

    <mat-paginator pageSize="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
