import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import {UtilisateurModel} from '../../models/utilisateur';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router, private db: AngularFirestore, private snackBar: MatSnackBar
  ) {
  }

  login(identifiant, motDePasse) {
    this.getUtilisateur(identifiant).subscribe((datas) => {
      if (datas.motDePasse === motDePasse && datas.role === 'administrateur') {
        this.loggedIn.next(true);
        this.router.navigate(['/projets']);
      } else {
        this.message('Identifiants incorrects !');
      }
    });
  }

  getUtilisateur(id: string): Observable<UtilisateurModel> {
    const productsDocuments = this.db.doc<UtilisateurModel>('utilisateurs/' + id);
    return productsDocuments.snapshotChanges()
      .pipe(
        map(changes => {
          const data = changes.payload.data();
          const id = changes.payload.id;
          return {id, ...data};
        }));
  }

  logout() {
    localStorage.setItem('identifiant', '');
    localStorage.setItem('motDePasse', '');

    localStorage.setItem('resterConnecte', 'false');

    this.loggedIn.next(false);
    this.router.navigate(['/connexion']);
  }

  message(message, duration = 3000) {
    this.snackBar.open(message, null, {duration});
  }
}
