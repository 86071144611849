<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>BBEFrance - Tableau de bord {{version}}</span>
    <span class="example-spacer"></span>
    <button mat-button routerLink="/utilisateurs" routerLinkActive="active" *ngIf="isLoggedIn$ | async">
      <mat-icon style="margin-left: 4px; margin-right: 4px;">people</mat-icon>
      Salariés
    </button>
    <button mat-button routerLink="/projets" routerLinkActive="active" *ngIf="isLoggedIn$ | async">
      <mat-icon style="margin-left: 4px; margin-right: 4px;">work</mat-icon>
      Projets
    </button>
    <button mat-button (click)="onLogout()" *ngIf="isLoggedIn$ | async">
      <mat-icon style="margin-left: 4px; margin-right: 4px;">power_settings_new</mat-icon>
      Déconnexion
    </button>
  </mat-toolbar-row>
</mat-toolbar>
