import {BrowserModule} from '@angular/platform-browser';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangerTechnicienBottomSheetComponent} from './projets.component';
import {MatListModule} from '@angular/material/list';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDividerModule} from '@angular/material/divider';

import {getFirestore, provideFirestore} from '@angular/fire/firestore';

import 'firebase/firestore';
import 'firebase/storage';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';

const config = {
  apiKey: 'AIzaSyCXg7lSSxoP3YW6_6syqq5kfViaBCTN1Cc',
  authDomain: 'bbefrance-db063.firebaseapp.com',
  databaseURL: 'https://bbefrance-db063.firebaseio.com',
  projectId: 'bbefrance-db063',
  storageBucket: 'bbefrance-db063.appspot.com',
  messagingSenderId: '812867934703',
  appId: '1:812867934703:web:534af69fa2eb903ab7be1a'
};

@NgModule({
  declarations: [
    ChangerTechnicienBottomSheetComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,

    provideFirebaseApp(() => initializeApp(config)),
    provideFirestore(() => getFirestore()),

    MatListModule,
    MatDividerModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class ProjetsModule {
}
