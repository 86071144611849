import { Router } from '@angular/router';
import { NavigationService } from './../services/navigation.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  version: string;

  isLoggedIn$: Observable<boolean>;

  constructor(private authService: AuthService, private navigationService: NavigationService, private router: Router) { }

  ngOnInit() {
    this.version = environment.version;
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  onLogout() {
    this.authService.logout();
  }

  afficherGestionServices() {
    this.router.navigate(['/suivi', { afficherServices: true }]);
    this.navigationService.service.next({ visible: true });
  }
}
