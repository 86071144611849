<div style="padding: 50px;">
  <mat-card color="light" style="border-radius: 0%;">
    <mat-card-header  style="margin-bottom: 5px;">
      <table>
        <tr>
          <td>
            <mat-card-title>Nouvel utilisateur</mat-card-title>
          </td>
          <td style="text-align: right;"><button mat-raised-button type="button" color="primary"
              (click)="valider()">Valider</button></td>
        </tr>
      </table>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <br>
      <form class="example-form">
        <table style="width: 100%;">
          <tr style="width: 100%;">
            <td style="width: 20%;">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Prénom et Nom</mat-label>
                <input matInput [(ngModel)]="nouvelUtilisateur.prenomNom" name="nouvelUtilisateur.prenomNom">
              </mat-form-field>
            </td>
            <td style="width: 20%;">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Début plage</mat-label>
                <input matInput [(ngModel)]="nouvelUtilisateur.debutPlage" name="nouvelUtilisateur.debutPlage">
              </mat-form-field>
            </td>
            <td style="width: 20%;">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Identifiant</mat-label>
                <input matInput [(ngModel)]="nouvelUtilisateur.identifiant" name="nouvelUtilisateur.identifiant">
              </mat-form-field>
            </td>
            <td style="width: 20%;">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Mot de passe</mat-label>
                <input matInput [(ngModel)]="nouvelUtilisateur.motDePasse" name="nouvelUtilisateur.motDePasse">
              </mat-form-field>
            </td>
            <td style="width: 20%;">
              <mat-form-field appearance="outline" class="example-full-width" required>
                <mat-label>Rôle</mat-label>
                <mat-select [(ngModel)]="nouvelUtilisateur.role" name="nouvelUtilisateur.role">
                  <mat-option value="technicien">Technicien</mat-option>
                  <mat-option value="administrateur">Administrateur</mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </table>
      </form>
    </mat-card-content>
  </mat-card>

  <br><br>

  <!-- <mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia">
</mat-form-field> -->
  <!-- <mat-divider></mat-divider> -->
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="prenomNom">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom et Nom </th>
        <td mat-cell *matCellDef="let row"> {{row.prenomNom}} </td>
      </ng-container>

      <ng-container matColumnDef="debutPlage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Début plage </th>
        <td mat-cell *matCellDef="let row"> {{row.debutPlage}} </td>
      </ng-container>

      <ng-container matColumnDef="identifiant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Identifiant </th>
        <td mat-cell *matCellDef="let row"> {{row.identifiant}} </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Rôle </th>
        <td mat-cell *matCellDef="let row"> {{row.role}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
        <td mat-cell *matCellDef="let row">
          <table style="float: right;">
            <tr>
              <td><button mat-stroked-button color="warn" (click)="supprimer(row.id)">
                  <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                </button></td>
            </tr>
          </table>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

    <mat-paginator pageSize="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
