import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {UtilisateursComponent} from './utilisateurs/utilisateurs.component';
import {ProjetsComponent} from './projets/projets.component';
import {ConnexionComponent} from './connexion/connexion.component';
import {AuthGuard} from './services/auth-guard.service';
import {AppComponent} from './app.component';
import {ApplicationComponent} from './application/application.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProjetsComponent
      }
    ]
  },
  {
    path: '',
    component: ConnexionComponent,
    children: [
      {
        path: 'connexion',
        component: ConnexionComponent
      }
    ]
  },
  {
    path: 'projets',
    component: ProjetsComponent
  },
  {
    path: 'utilisateurs',
    component: UtilisateursComponent
  },
  {
    path: 'application',
    component: ApplicationComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
