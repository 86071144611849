import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {DialogCompararaisonImageComponent} from './dialogs/comparaison-images/comparaison-images.component';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UtilisateursComponent} from './utilisateurs/utilisateurs.component';
import {ProjetsComponent} from './projets/projets.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';

import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import 'firebase/firestore';
import 'firebase/storage';
import {ConnexionComponent} from './connexion/connexion.component';

import {MaterialFileInputModule} from 'ngx-material-file-input';
import {HeaderComponent} from './header/header.component';
import {ProjetsModule} from './projets/projets.module';
import {getFrenchPaginatorIntl} from './french-paginator-intl';
import {ApplicationComponent} from './application/application.component';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';

import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {PanelModule} from 'primeng/panel';
import {AccordionModule} from 'primeng/accordion';
import {TableModule} from 'primeng/table';
import {ChartModule} from 'primeng/chart';
import {DialogModule} from 'primeng/dialog';
import {CardModule} from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ChipModule} from 'primeng/chip';


import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';

registerLocaleData(localeFr);

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

const config = {
  apiKey: 'AIzaSyCXg7lSSxoP3YW6_6syqq5kfViaBCTN1Cc',
  authDomain: 'bbefrance-db063.firebaseapp.com',
  databaseURL: 'https://bbefrance-db063.firebaseio.com',
  projectId: 'bbefrance-db063',
  storageBucket: 'bbefrance-db063.appspot.com',
  messagingSenderId: '812867934703',
  appId: '1:812867934703:web:534af69fa2eb903ab7be1a'
};

@NgModule({
  declarations: [
    AppComponent,
    UtilisateursComponent,
    ProjetsComponent,
    ConnexionComponent,
    HeaderComponent,
    ApplicationComponent,
    DialogCompararaisonImageComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

    MaterialFileInputModule,

    ProjetsModule,

    ButtonModule,
    CalendarModule,
    PanelModule,
    AccordionModule,
    TableModule,
    ChartModule,
    DropdownModule,
    InputTextModule,
    MultiSelectModule,
    DialogModule,
    CardModule,
    TabViewModule,
    MessagesModule,
    MessageModule,
    ChipModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl()},
    {provide: LOCALE_ID, useValue: 'fr-FR'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
