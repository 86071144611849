import {DialogCompararaisonImageComponent} from './../dialogs/comparaison-images/comparaison-images.component';
import {ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {UtilisateurModel} from '../../models/utilisateur';
import {AngularFirestore} from '@angular/fire/compat/firestore';

import {map} from 'rxjs/operators';
import {ProjetModel} from '../../models/projet';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {FileInput} from 'ngx-material-file-input';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-projets',
  templateUrl: './projets.component.html',
  styleUrls: ['./projets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjetsComponent implements OnInit {
  nouveauProjet = new ProjetModel();
  displayedColumns: string[] = ['libelle', 'nbPoteaux', 'zone', 'utilisateur', 'dateCloture', 'actif', 'actions'];
  dataSource: MatTableDataSource<any>;

  zones = new Array<string>();
  zonesSearch = new Array<string>();

  utilisateurs: any;

  fichierKml: FileInput;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private db: AngularFirestore,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private afStorage: AngularFireStorage,
    private authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private bottomSheet: MatBottomSheet) {

    this.db.collection('projets').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as ProjetModel;
        data.id = a.payload.doc.id;
        return data.zone;
      }))
    ).subscribe((datas) => {
      this.zones = datas.filter(this.onlyUnique).sort();
      this.zonesSearch = this.zones;
    });

    this.db.collection('projets', ref => ref.where('archive', '==', false)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as ProjetModel;
        const id = a.payload.doc.id;

        return {
          id,
          utilisateur: data.utilisateur,
          libelle: data.libelle,
          nbPoteauxSaisis: 0,
          nbPoteaux: (a.payload.doc.data() as any).reprises?.length,
          kml: data.kml,
          zone: data.zone,
          actif: data.actif,
          archive: data.archive,
          dateCloture: data.dateCloture
        };
      }))
    ).subscribe((datas) => {
      this.dataSource = new MatTableDataSource(datas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.db.collection('utilisateurs').snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as UtilisateurModel;
        const id = a.payload.doc.id;
        return {
          id,
          prenomNom: data.prenomNom,
          debutPlage: data.debutPlage,
          identifiant: data.identifiant,
          role: data.role
        };
      }))
    ).subscribe((datas) => {
      this.utilisateurs = datas;
    });
  }

  ngOnInit() {
    this.authService.isLoggedIn.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.navigate(['/connexion']);
      }
    });
  }

  filterZone() {
    this.zonesSearch = this.zones.filter(x => x.toLowerCase().includes(this.nouveauProjet.zone.toLowerCase()));
  }

  nombreReprises(reprises, kml) {
    const body = {
      kml
    };
    this.http.post('https://bbefrance.azurewebsites.net/api/CompteurPoteaux', body).subscribe(value => {
      alert(reprises + ' / ' + value);
    });
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  archiver(projet) {
    projet.archive = true;
    this.db.collection('projets').doc(projet.id).set(projet)
      .then(_ => {
        this.message('Projet archivé avec succès !');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changerEtat(projet, actif) {
    this.db.collection('projets').doc(projet.id).ref.update({
      actif,
      dateCloture: new Date()
    })
      .then(_ => {
        if (actif) {
          this.message('Projet ouvert avec succès !');
        } else {
          this.message('Projet fermé avec succès !');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  supprimer(id) {
    if (confirm('En continuant vous supprimerez le projet, les poteaux et les images du projet, si vous voulez juste archiver le projet cliquez sur annuler puis cliquez le bouton rose du projet en question. Confirmez-vous la suppression du projet ?')) {
      this.db.collection('projets').doc(id).delete().then(_ => {
        this.message('Projets supprimé avec succès !');
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  valider() {
    this.nouveauProjet.libelle = this.nouveauProjet.libelle.trim();
    if (!this.nouveauProjet.libelle) {
      this.message('Libellé obligatoire !');
    } else if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]é^éïîôöàâäè/.test(this.nouveauProjet.libelle.toLowerCase()) || this.nouveauProjet.libelle.includes(' ')) {
      this.message('Le libellé ne doit pas contenir de caractères spéciaux.');
    } else if (!this.nouveauProjet.zone) {
      this.message('Zone obligatoire !');
    } else if (!this.nouveauProjet.utilisateur) {
      this.message('Utilisateur obligatoire !');
    } else if (!this.fichierKml) {
      this.message('Fichier KML obligatoire !');
    } else {
      const blob = new Blob([this.fichierKml.files[0]], {type: this.fichierKml.files[0].type});
      this.afStorage.ref(this.nouveauProjet.libelle + '/' + this.fichierKml.files[0].name).put(blob).then((datas) => {
        datas.ref.getDownloadURL().then((kml) => {
          this.nouveauProjet.kml = kml;
          this.db.collection('projets').add(JSON.parse(JSON.stringify(this.nouveauProjet)))
            .then(_ => {
              this.message('Projet ajouté avec succès !');
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
    }
  }

  genererKml(id, libelle) {
    let poteaux = '';
    this.db.collection('projets').doc(id).collection('poteaux').valueChanges().subscribe((datas) => {
      poteaux += '<?xml version="1.0" encoding="UTF-8"?>';
      poteaux += '<kml xmlns="http://www.opengis.net/kml/2.2">';
      poteaux += '<Document id="root_doc">';
      poteaux += '<Folder>';
      poteaux += '<name>' + libelle + '</name>';
      datas.forEach(poteau => {
        poteaux += `
          <Placemark>
            <name>${poteau.name}</name>
            <ExtendedData>
              <Data name="TYPE"><value>${poteau.type ?? ''}</value></Data>
              <Data name="nom"><value>${poteau.name ?? ''}</value></Data>
              <Data name="ENVIRONNEMENT"><value>${poteau.environnement ?? ''}</value></Data>
              <Data name="ENVIRONNEMENT_ELECTRIQUE"><value>${poteau.environnementElectrique ?? ''}</value></Data>
              <Data name="hauteur"><value>${poteau.height ?? ''}</value></Data>
              <Data name="MATERIEL"><value>${poteau.materiel ?? ''}</value></Data>
              <Data name="CLASSE"><value>${poteau.className ?? ''}</value></Data>
              <Data name="EFFORT"><value>${poteau.effort ?? ''}</value></Data>
              <Data name="ELAGAGE"><value>${poteau.elagage ?? ''}</value></Data>
              <Data name="ENDOMMAGE"><value>${poteau.damaged ?? ''}</value></Data>
              <Data name="ILLISIBLE"><value>${poteau.illegible ?? ''}</value></Data>
              <Data name="DEUX_FILS"><value>${poteau.twoCables ?? ''}</value></Data>
              <Data name="CABLE_VERS_FACADE"><value>${poteau.cableToFacade ?? ''}</value></Data>
              <Data name="MALT"><value>${poteau.malt ?? ''}</value></Data>
              <Data name="RAS_BT"><value>${poteau.rasBt ?? ''}</value></Data>
              <Data name="PRIVE"><value>${poteau.privateState ?? ''}</value></Data>
              <Data name="ADDUCTION"><value>${poteau.adduction ?? ''}</value></Data>
              <Data name="EP"><value>${poteau.ep ?? ''}</value></Data>
              <Data name="ETIQUETTE_JAUNE"><value>${poteau.yellowLabel ?? ''}</value></Data>
              <Data name="ETIQUETTE_ROUGE"><value>${poteau.redLabel ?? ''}</value></Data>
              <Data name="FAMILLE"><value>${poteau.famille ?? ''}</value></Data>
              <Data name="INACCESSIBLE"><value>${poteau.inaccessible ?? ''}</value></Data>
              <Data name="OBSERVATION"><value>${poteau.observation ?? ''}</value></Data>
              <Data name="ACCESSIBILITE_VEHICULE"><value>${poteau.accessibiliteVehicule ?? ''}</value></Data>
              <Data name="CONTROLE_VISUEL"><value>${poteau.controleVisuel ?? ''}</value></Data>
              <Data name="CONTROLE_VERTICALITE"><value>${poteau.controleVerticalite ?? ''}</value></Data>
              <Data name="CONTROLE_FLAMBEMENT"><value>${poteau.controleFlambement ?? ''}</value></Data>
              <Data name="CONTROLE_VOISINAGE_ELEC"><value>${poteau.controleVoisinageElec ?? ''}</value></Data>
              <Data name="CONTROLE_POINTE"><value>${poteau.controlePointe ?? ''}</value></Data>
              <Data name="CONTROLE_SECOUSSE"><value>${poteau.controleSecousse ?? ''}</value></Data>
              <Data name="CONTROLE_PERCUTION"><value>${poteau.controlePercurtion ?? ''}</value></Data>
              <Data name="BON_ETAT"><value>${poteau.bonEtat ?? ''}</value></Data>
              <Data name="PINCE_EA"><value>${poteau.pinceEa ?? ''}</value></Data>
              <Data name="PINCE_ES"><value>${poteau.pinceEs ?? ''}</value></Data>
            </ExtendedData>
            <Point>
              <coordinates>${poteau.location.toString().split(',')[1] + ',' + poteau.location.toString().split(',')[0]}</coordinates>
            </Point>
          </Placemark>
        `;
      });
      poteaux += '</Folder>';
      poteaux += '</Document>';
      poteaux += '</kml>';

      const fichierPoteaux = libelle + '/' + libelle + '-POTEAUX.kml';
      this.afStorage.ref(fichierPoteaux).getDownloadURL().subscribe(
        url => {
          // File exists, delete it
          this.afStorage.ref(fichierPoteaux).delete().toPromise().then(() => {
            this.afStorage.ref(fichierPoteaux).putString(poteaux).then(() => {
              this.message('KML généré avec succès lancer la synchronisation pour les récuperer !', 5000);
            });
          }).catch(error => {
            console.error('Error deleting file:', error);
          });
        },
        error => {
          this.afStorage.ref(fichierPoteaux).putString(poteaux).then(() => {
            this.message('KML généré avec succès lancer la synchronisation pour les récuperer !', 5000);
          });
        }
      );
    });

    let liaisons = '';
    this.db.collection('projets').doc(id).collection('liaisons').valueChanges().subscribe((datas) => {
      liaisons += '<?xml version="1.0" encoding="UTF-8"?>';
      liaisons += '<kml xmlns="http://www.opengis.net/kml/2.2">';
      liaisons += '<Document id="root_doc">';
      liaisons += '<Folder>';
      liaisons += '<name>' + libelle + '</name>';
      datas.forEach(liaison => {
        liaisons += `
          <Placemark>
            <ExtendedData>
              <Data name="CABLE_A_T1"><value>${liaison.cableAT1 ?? ''}</value></Data>
              <Data name="CABLE_D_T1"><value>${liaison.cableDT1 ?? ''}</value></Data>
              <Data name="HAUTEUR_T1"><value>${liaison.heightT1 ?? ''}</value></Data>
              <Data name="CABLE_A_T2"><value>${liaison.cableAT2 ?? ''}</value></Data>
              <Data name="CABLE_D_T2"><value>${liaison.cableDT2 ?? ''}</value></Data>
              <Data name="HAUTEUR_T2"><value>${liaison.heightT2 ?? ''}</value></Data>
              <Data name="CABLE_A_T3"><value>${liaison.cableAT3 ?? ''}</value></Data>
              <Data name="CABLE_D_T3"><value>${liaison.cableDT3 ?? ''}</value></Data>
              <Data name="HAUTEUR_T3"><value>${liaison.heightT3 ?? ''}</value></Data>
              <Data name="DISTANCE"><value>${liaison.distance ?? ''}</value></Data>
            </ExtendedData>
            <LineString>
              <coordinates>
                ${liaison.postFrom.split(',')[1] + ',' + liaison.postFrom.split(',')[0]} ${liaison.postTo.split(',')[1] + ',' + liaison.postTo.split(',')[0]}
              </coordinates>
            </LineString>
          </Placemark>
        `;
      });
      liaisons += '</Folder>';
      liaisons += '</Document>';
      liaisons += '</kml>';

      const fichierLiaisons = libelle + '/' + libelle + '-LIAISONS.kml';
      this.afStorage.ref(fichierLiaisons).getDownloadURL().subscribe(
        url => {
          // File exists, delete it
          this.afStorage.ref(fichierLiaisons).delete().toPromise().then(() => {
            this.afStorage.ref(fichierLiaisons).putString(liaisons).then(() => {
              this.message('KML généré avec succès lancer la synchronisation pour les récuperer !', 5000);
            });
          }).catch(error => {
            console.error('Error deleting file:', error);
          });
        },
        error => {
          this.afStorage.ref(fichierLiaisons).putString(liaisons).then(() => {
            this.message('KML généré avec succès lancer la synchronisation pour les récuperer !', 5000);
          });
        }
      );
    });
  }

  changerTechnicien(projet) {
    this.bottomSheet.open(ChangerTechnicienBottomSheetComponent, {data: {utilisateurs: this.utilisateurs, projet}});
  }

  genererKmlManquants(projet) {
    const poteauxOk = [];

    this.afStorage.ref(projet.libelle + '/images').listAll().subscribe((res) => {
      res.prefixes.forEach((folderRef) => {
        poteauxOk.push(folderRef.name);
      });
      const body = {
        kml: projet.kml,
        name: projet.libelle,
        folders: poteauxOk
      };
      this.http.post('https://bbefrance.azurewebsites.net/api/PoteauxProjet', body, {responseType: 'blob'}).subscribe(value => {
        this.downLoadFile(value, projet.libelle + '.kml');
      });
    });
  }

  downLoadFile(data: any, fileName) {
    const blob = new Blob([data], {type: 'application/vnd.google-earth.kml+xml'});
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  comparaisonImage(projet) {
    this.dialog.open(DialogCompararaisonImageComponent, {
      width: '650px',
      data: {projet}
    });
  }

  message(message, duration = 3000) {
    this.snackBar.open(message, null, {duration});
  }
}

@Component({
  selector: 'app-changertechnicien-bottomsheet',
  template: `
      <mat-nav-list>
          <a href="#" *ngFor="let u of utilisateurs" mat-list-item (click)="select($event, u.id)">
              <span mat-line>{{ u.prenomNom }}</span>
          </a>
      </mat-nav-list>
  `,
})
export class ChangerTechnicienBottomSheetComponent {
  projet: ProjetModel;
  utilisateurs: Array<UtilisateurModel>;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ChangerTechnicienBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public snackBar: MatSnackBar,
    private db: AngularFirestore) {
    this.projet = data.projet;
    this.utilisateurs = data.utilisateurs;
  }

  select(event: MouseEvent, id: string): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();

    this.db.collection('projets').doc(this.projet.id).ref.update({utilisateur: id})
      .then(_ => {
        this.message('Projet modifié avec succès !');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  message(message, duration = 3000) {
    this.snackBar.open(message, null, {duration});
  }
}
