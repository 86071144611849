export class ProjetModel {
  id: string;
  utilisateur: string;
  zone: string;
  libelle: string;
  kml: string;
  actif = true;
  archive = false;
  dateCloture: Date;
}
