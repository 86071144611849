<h1 mat-dialog-title>{{data.projet.libelle}} ({{this.poteaux.length}} poteaux) -
  <span class="ok" *ngIf="!displayResult()">OK</span>
  <span class="ko" *ngIf="displayResult()">KO</span>
</h1>
<div mat-dialog-content>
  <table>
    <tr>
      <th>Poteau</th>
      <th>Images sur le téléphone</th>
      <th>Images sur le serveur</th>
      <th>Date d'ajout du poteau</th>
    </tr>
    <tr *ngFor="let poteau of poteaux;  index as i;">
      <td style="text-align: center;">
            <span *ngIf="poteaux[i].ok" class="ok">
              {{poteau.name}}
            </span>
        <span *ngIf="!poteaux[i].ok" class="ko">
              {{poteau.name}}
            </span>
      </td>
      <td style="text-align: center;">{{poteau.telephone}}</td>
      <td style="text-align: center;">{{poteau.serveur}}</td>
      <td style="text-align: center; font-size: 11pt">{{poteau.dateAdded.seconds * 1000 | date: 'dd/MM/yyyy H:mm'}}</td>
    </tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="fermer()">Fermer</button>
</div>
