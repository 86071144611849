<div style="display: flex; height: 400px;">
  <mat-card style="margin: auto; width: 50%;">
    <mat-card-header>
      <mat-card-title>Connexion</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
      <br>
      <table style="width: 100%;">
        <tr>
          <td style="width: 50%;">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Identifiant</mat-label>
              <input matInput [(ngModel)]="identifiant" name="identifiant">
              <mat-icon matSuffix>perm_identity</mat-icon>
            </mat-form-field>
          </td>
          <td style="width: 50%;">
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Mot de passe</mat-label>
              <input matInput [(ngModel)]="motDePasse" name="motDePasse" type="password">
              <mat-icon matSuffix>lock</mat-icon>
            </mat-form-field>
          </td>
        </tr>
      </table>

      <mat-checkbox class="example-margin" [(ngModel)]="resterConnecte" color="primary">Rester connecter</mat-checkbox>
      <button mat-raised-button color="primary" style="float: right;" (click)="login()">Connexion</button>
      <br><br>
    </mat-card-content>
  </mat-card>
</div>

<div style="display: flex">
  <div style="margin: auto">
    {{version}}
  </div>
</div>
