import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html'
})
export class ConnexionComponent implements OnInit {
  version = environment.version;

  identifiant: string;
  motDePasse: string;

  resterConnecte = true;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    if (localStorage.getItem('resterConnecte')) {
      this.identifiant = localStorage.getItem('identifiant');
      this.motDePasse = localStorage.getItem('motDePasse');

      this.login();
    }
  }

  login() {
    if (this.resterConnecte) {
      localStorage.setItem('identifiant', this.identifiant);
      localStorage.setItem('motDePasse', this.motDePasse);

      localStorage.setItem('resterConnecte', 'true');
    } else {
      localStorage.setItem('identifiant', '');
      localStorage.setItem('motDePasse', '');

      localStorage.setItem('resterConnecte', 'false');
    }

    this.authService.login(this.identifiant, this.motDePasse);
  }
}
