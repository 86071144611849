import { environment } from './../environments/environment';
import { Component } from '@angular/core';

import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'BBEFranceWeb';

  constructor() {
    firebase.initializeApp(environment.firebase);
  }
}
